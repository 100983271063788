const form = document.querySelector('form');
const dialog = document.querySelector('dialog');
const formText = [...document.querySelectorAll('input[type=text]')];

form.addEventListener('submit', e => {
  e.preventDefault();

  const formData = new FormData(form);

  formData.forEach((value, key) => {
    validateText(value, key);
  });

  if ([...form.querySelectorAll('.error')].length > 0) {
    return;
  }

  formSend(formData);
});

// Send form
async function formSend(formData) {
  const formPhpLink = form.getAttribute('action');

  const response = await fetch(formPhpLink, {
    method: 'POST',
    body: formData,
    header: 'Content-Type: text/html; charset=utf-8',
  });

  if (response.ok) {
    dialog.showModal();
    form.reset();

    return response;
  } else {
    return Promise.reject(response);
  }
}

document.querySelector('.dialog__close').addEventListener('click', () => {
  dialog.close();
});

setTimeout(() => {
  dialog.close();
}, 5000);

// Add show class to error
function showErrorMessage(error) {
  error.closest('label').classList.add('error');
}

// Remove show class to error
function hideErrorMessage(error) {
  error.closest('label').classList.remove('error');
}

function validateText(value, key) {
  formText.forEach(item => {
    if (item.getAttribute('name') !== key) {
      return;
    }

    if (value.length < 2 || value.length > 12) {
      showErrorMessage(item);
    } else {
      hideErrorMessage(item);
    }
  });
}
