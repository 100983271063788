const listItems = [...document.querySelectorAll('.client__item')];

listItems.forEach(item => {
  item.addEventListener('click', e => {
    switchActiveItem(e);
  });
});

function switchActiveItem(e) {
  listItems.forEach(item => {
    item.classList.remove('active');
  });

  if (e.target.nodeName.toLowerCase() !== 'h3') {
    return;
  }

  e.target.parentNode.classList.add('active');
}
