const menuItem = [...document.querySelectorAll('.nav__item--dropdown')];

menuItem.forEach(link => {
  link.addEventListener('click', menuNavDropDown);
});

function menuNavDropDown(e) {
  if (!e.currentTarget.classList.contains('nav__item--dropdown')) {
    item.classList.remove('open');
    return;
  }

  e.currentTarget.classList.toggle('open');
}
