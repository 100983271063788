const loginForm = document.querySelector('.login');

if (!loginForm) {
  return;
}

loginForm.addEventListener('submit', e => {
  e.preventDefault();

  e.target.closest('.login').classList.add('hide');
  document.querySelector('.show-login').classList.add('show-message');
});
